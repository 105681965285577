import React, { useContext, useEffect, useState } from 'react';
import { Dispatch } from '../providers/state';

const Page404 = ({ data }) => {
  const dispatch = useContext(Dispatch);

  useEffect(() => {
    dispatch({ route: '/' })
  }, [])

  return (
    <div id="dashboard" className={``}>
      {/* Spooky */}
    </div>
  );
};
export default Page404;
